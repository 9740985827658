.otp-form-container {
  box-sizing: border-box;

  min-height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .otp-form {
    width: 100%;
  }

  .otp-input-container {
    width: 100%;
  }

  .opt-input {
    // resetting input style
    appearance: none;
    border: none;
    outline: none;

    flex-grow: 1;
    flex-basis: 1;
    height: 50px;
    font-size: 1.5rem;

    border: 1px solid gray;
    border-radius: 20px;

    &:focus {
      border-color: transparent;
      outline: 2px solid royalblue;

      box-shadow: 10px 10px 100px -18px rgba(0, 0, 0, 1);
      -webkit-box-shadow: 10px 10px 100px -18px rgba(0, 0, 0, 1);
      -moz-box-shadow: 10px 10px 100px -18px rgba(0, 0, 0, 1);
    }
  }

  .verify-otp-button-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.otp-modal-header.modal-header {
  .btn.btn-close {
    box-shadow: none !important;
  }
}
